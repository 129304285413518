.what-crypit-comp{
    background: linear-gradient(105.76deg, rgba(255, 126, 17, 0.2) 1.35%, rgba(1, 31, 26, 0.2) 100%),url("../../assets/img-2.0/what-crypit-bg.png");
    background-position: center;
    padding: 0px 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-image: ; */
    background-repeat: no-repeat;
    background-size: cover;
}

.crypit-phone{
    height: 500px;
    margin: 30px 0 0;
}
.heading{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 56px;
line-height: 130%;
/* identical to box height, or 73px */
color: #111310;
}
.underline{
    background: linear-gradient(96.31deg, #011F1A -16.31%, #10C0AE 100%);
    height: 8px;
    width: 113px;
}
.paragraph{
    width: 505px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #414240;
    margin-top: 35px;
}

@media (min-width:327px) and (max-width:999px) {
    .what-crypit-comp{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 25px;
    }
    .heading{
        font-size: 30px;
    }
    .paragraph{
        width: auto;
    }
    .crypit-phone{
    height: 338px;
    }
}
@media (min-width:1100px) and (max-width:1199px) {
    .paragraph{

    }
    .crypit-phone {
        /* width: 515px; */
    }
    .what-crypit-comp{
        padding: 0px 70px;

    }
}
@media (min-width:1000px) and (max-width:1099px) {
    .paragraph{
        width: 450px;
    }
    .heading{
        font-size: 42px;
    }
    .crypit-phone {
        /* width: 500px; */
        margin-right: 20px;
    }
    .what-crypit-comp{
        padding: 0px 0 0 50px;

    }
}