@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.how-it-works{
    padding: 150px 120px 100px;
    background-color: #011F1A;
    position: relative;
    height: 950px;
}
.step1{
position: absolute;
top: 325px;
left: 260px;
}

.step2{
    position: absolute;
    top: 500px;
    left: 900px;
}
.step3{
    position: absolute;
    top: 700px;
    left: 260px;
}
.number-opacity{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 150%;
color: #FBE043;
opacity: 0.5;
}
.step-head{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 150%;
color: #FFFFFF;
padding: 0 24px;
width: 350px;
}

.step-desc{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 150%;
color: #A6A6A6;
width: 380px;
padding: 12px 30px 12px 57px;
}

.line1{
    position: absolute;
    top: 368px;
    left: 585px;
}
.line2{
    position: absolute;
    top: 636px;
    left: 623px;
}
.arrow1{
    position: absolute;
    top: 543px;
    left: 740px;
}
.arrow2{
    position: absolute;
    top: 800px;
    left: 610px;
}
.ecllipse1{
    position: absolute;
    top: 575px;
    left: 732px;
}
.ecllipse2{
    position: absolute;
    top: 850px;
    left: 653px;
}

@media (min-width:1000px) and (max-width:1099px) {
    .how-it-works{
        padding: 150px 50px 10px;
    }
    .step1{
        left: 100px;
    }
    .step2{
      left: 630px; 
    }
    .step3{
        left: 100px;
    }
    .line1 {
        position: absolute;
        top: 335px;
        left: 506px;
    }
    .arrow1 {
        top: 487px;
        left: 610px;
    }
}
@media (min-width:1100px) and (max-width:1200px) {
    .step2 {
        position: absolute;
        top: 500px;
        left: 721px;
    }
    .step1 {
        position: absolute;
        top: 325px;
        left: 160px;
    }
    .step3 {
        position: absolute;
        bottom: 0;
        left: 160px;
    }
    .line1{
        top: 328px;
    }
}

@media (min-width:1201px) and (max-width:1300px) {
    .step2 {
        position: absolute;
        top: 500px;
        left: 750px;
    }
    .step1 {
        position: absolute;
        top: 325px;
        left: 160px;
    }
    .step3 {
        position: absolute;
        bottom: 0;
        left: 160px;
    }
    .line1{
        top: 328px;
    }
}

@media (min-width:325px) and (max-width:999px) {
    .how-it-works{
        padding: 20px 25px 10px;
        height: 750px;
    }
    .feature-sec-heading{
        font-size: 32px;
    }
    .step-head{
        font-size: 16px;
        padding: 0 20px;
        width: auto;
    }
    .step-desc{
        font-size: 12px;
        font-size: 12px;
        padding: 5px 46px 5px 46px;
        width: 250px;
    }
    .step1 {
        width: 300px;
        top: 125px;
        left: 20px;
    }
    .step2 {
        width: 300px;
        top: 300px;
        left: 20px;
    }
    .step3 {
        width: 300px;
        top: 500px;
        left: 20px;
    }
    .line1,.line2{
        display: none;
    }
    .arrow1 {
        position: absolute;
        top: 243px;
        left: 250px;
    }
    .arrow2 {
        position: absolute;
        top: 658px;
        left: 300px;
    }
    .ecllipse1,.ecllipse2{
       display: none;
    }
    
}