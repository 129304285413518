

.why-crypit{
padding: 0 120px ;
background-color: #011F1A; position: relative;
}

.logo-head-why{
   width: 30px;
   height: 30px;
}

.why-crypit-container{
    width: 717px;
    height: 455px;
    padding: 30px;
    background-color: #fff!important;
    background: linear-gradient(105.76deg, rgba(255, 126, 17, 0.2) 1.35%, rgba(1, 31, 26, 0.2) 100%);

    border-radius: 8px;
    /* background: linear-gradient(105.76deg, rgba(255, 126, 17) 1.35%, rgba(1, 31, 26) 100%); */
    position: absolute;
    bottom: 0;
    left: 675px;
}
.container-data{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    align-items: center;    
    margin: 35px 0 20px;
}
.point-data{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 150%;
/* or 27px */
width: 275px;
color: #414240;
padding: 12px 16px 12px 8px;
margin: 5px;
}

@media (min-width:1250px) and (max-width:1458px) {
    .why-crypit-container{
        position: absolute;
        bottom: 0;
        left: 450px!important;
        height: 437px!important;
    }

}
@media (min-width:1150px) and (max-width:1249px) {
    .why-crypit{
            padding: 0;
    }
    .why-crypit-container{
        position: absolute;
        bottom: 0;
        left: 350px!important;
        
    height: 432px!important;

    }
    
}

@media (min-width:1000px) and (max-width:1149px) {
    .why-crypit{
        padding: 0;
}
.why-crypit-container{
    left: 275px;
    width: 617px;
}
.point-data{
    width: 240px;
    font-size: 15px;
}
}

@media (min-width:325px) and (max-width:999px) {
    .why-crypit{
        padding: 0 0 300px 0;
}
    .why-crypit-img {
        width:100%;
        height: 400px;
    }
    .why-crypit-container{
        left: 0;
        width: 84%;
        font-size: 15px;
        top: 166px;
        height: 75%;
    }
    .point-data{
        font-size: 12px;
        margin: 0;
    }
}