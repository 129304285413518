.about-desc-cont {
    padding: 96px 120px;
    background: linear-gradient(105.76deg, rgba(255, 126, 17, 0.2) 1.35%, rgba(1, 31, 26, 0.2) 100%);
}

.about-desc-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 130%;
    width: 944px;
    color: #111310;
    padding-bottom: 48px;
}

.about-desc-para {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    width: 637px;

    color: #111310;
}

@media (min-width:1000px) and (max-width:1100px) {
    .about-desc-cont {
        padding: 96px 50px;
    }

    .story-sec-cont {
        padding: 140px 50px 10px !important;
    }

    .storyImg {
        width: 414px;
        height: 500px;
    }

    .vm-img1,
    .vm-img2,
    .vm-img3,
    .vm-img4 {
        width: 200px;
        height: 250px;
    }

    .vision-mission-details {
        margin-left: 0px!important;
    }
}

@media (min-width:325px) and (max-width:999px) {

    .about-desc-cont {
        padding: 40px 25px;
    }

    .about-desc-title {
        width: auto;
        font-size: 32px;
        text-align: center;
    }

    .about-desc-para {
        font-size: 12px;
        width: auto;
        text-align: center;
    }

    .story-sec-cont {
        padding: 40px 50px 10px !important;
    }

    .story-sec,
    .vision-mission-cont {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: center;
    }

    .storyImg {
        width: 233px !important;
        height: 304px !important;
        margin: auto;
    }

    .vm-img1,
    .vm-img2,
    .vm-img3,
    .vm-img4 {
        width: 134px !important;
        height: 174px !important;
        margin: auto;
    }

    .vm-img3 {
        margin: -43px 0 0 0 !important;
    }

    .vm-img2 {
        margin: 120px 0px 0 15px !important;
    }

    .vm-img4 {
        margin: 30px 0px 0 15px !important;
    }

    .story-Cont {
        margin-top: 30px;
        margin-left: 0px!important;
    }
    
    .story-Cont {
        width: auto !important;
    }

    .story-cont-desc {
        width: auto !important;
        text-align: justify;
    }

    .vision-mission-details {
        margin-left: 0px !important;
    }

    .story-sub-topic {
        width: auto!important;
    }
}