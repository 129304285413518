.feature-Section{
    background-color: #011F1A;
    padding: 150px 120px 0px;
}
.feature-sec-heading{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 56px;
line-height: 130%;
/* identical to box height, or 73px */
color: #fff;
}
.feature-sec-underline{
    background: linear-gradient(95.44deg, #FBE043 -1.4%, #FF7E11 100%);
    height: 8px;
    width: 113px;
}
.Feature1,.Feature2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 103px;
}
.feature1-details{
    margin-right: 95px;
    width: 550px; 
}
.feature2-details{
    margin-left: 95px;
    width: 550px; 
}
.feature1-heading{
    font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 150%;
text-align: right;
color: #fff;
letter-spacing: 2px;
}

.feature2-heading{
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    text-align: left;
    color: #fff;
    letter-spacing: 2px;
}

.feature1-desc{    
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 150%;
/* or 24px */
text-align: right;
color: #FFFFFF;
padding-top: 20px;
margin-left: 90px;
}

.feature2-desc{    
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    /* or 24px */
    text-align: left;
    color: #FFFFFF;
    padding-top: 20px;
    }

.feature-img{
    /* width: 333.32px; */
height: 436px;
}
.crypit-just{
  width: 100%;
height:auto;
/* margin-left: 117px; */
margin-top: 75px;
margin-bottom: 75px;
}

@media (min-width:1000px) and (max-width:1099px) {
    .feature-Section{
        padding: 150px 50px 0px;
    }
    .crypit-just{
      margin-left: 50px;
      }
}
@media (min-width:327px) and (max-width:999px) {
    .feature-Section {
        padding: 70px 30px 0px;
    }
    .Feature1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
    .Feature2{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
    }
    .feature1-heading{
        font-size: 28px;
        text-align: center;
    }
    .feature2-heading{
        font-size: 28px;
        text-align: center;
    }
    .feature-img {
        /* width: 258px; */
        height: 344px;
    }
    .feature1-desc,.feature2-desc{
        margin-left: 0px;
        text-align: center;
        font-size:16px;
        padding-bottom: 20px;
    }
    .feature1-details{
        margin-right: 0px;
    width: auto;
    }
    .feature2-details {
        margin-left: 0px;
        width: auto;
    }
    .crypit-just {
        width: 100%;
        height: auto;
        /* margin-left: 28px; */
        margin-top: 50px;
        margin-bottom: 50px;
    }

}