.contact-section{
    background: linear-gradient(105.76deg, rgba(255, 126, 17, 0.2) 1.35%, rgba(1, 31, 26, 0.2) 100%);
        padding: 144px 167px 50px;
        display: flex;
        justify-content: space-between;
     
}
.contact-card{
    width: 477px;
}
.address-card{
    width: 429px;
}

.input-email{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    margin: 20px 0;
    padding: 10px 0;
    outline-style: none;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Montserrat';
}

.input-email::placeholder {
    color: #000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
}
.textarea-email{
    background-color: transparent;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    width: 100%!important;
}
.textarea-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #111310;
margin: 5px 0;
padding-bottom: 10px;
outline-style: none;
}
.email-join-cta{
    margin: 25px 0 15px;
    padding: 18px 36px;
    background: linear-gradient(96.31deg, #FBE043 -16.31%, #FF7E11 100%);
    border-radius: 10px;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
text-transform: capitalize;
color: #111310;
cursor: pointer;
width: 200px;
text-align: center;
}

.addr-text{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
width: 239px;
color: #111310;
}
.phone-email{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 56px 0;
}
.phone-head{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 12px;
    color: #111310;
}

.phone-number{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 150%;
/* identical to box height, or 27px */


color: #111310;
}

.location-img{
    width: 500px;
    padding: 24px 0 ;
}
@media (min-width:1000px) and (max-width:1100px) {
    .contact-card{
        width: 425px;
    }
    .contact-section{
    padding: 144px 50px 50px!important;
    }
    .location-img{
        width: 350px!important;
        padding: 24px 0 ;
    }
}
@media (min-width:1101px) and (max-width:1200px) {
    .contact-section{
    padding: 144px 50px 50px!important;
    }
    .location-img{
        width: 400px!important;
        padding: 24px 0 ;
    }
}
@media (min-width:1201px) and (max-width:1300px) {
    .contact-section{
    padding: 144px 75px 50px!important;
    }
}
@media (min-width:325px) and (max-width:999px) {

    .contact-section{
        padding:30px 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-card{
        width: 300px;
    }
    .address-card{
        width: 330px;
    }
    .location-img{
        width: 325px;
    }
    .phone-email{
        margin: 25px 0;
    }
    .phone-number,.phone-head, .addr-text{
        font-size: 14px;
        padding-bottom: 6px;
    }
}