.text-highlight {
    color: #EBB044;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.pages {
    color: #316685;
    text-align: center;
    font-size: calc(1.5rem + 2vw);
    margin-top: 10%;
}

.nav-sticky-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 0;

    z-index: 10000;
}

.text-blue {
    color: rgb(37, 47, 90);
}

.navbar-nav {
    /* height: 60px; */
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: #16312c;
    padding: 20px 120px 0px;

}

.logo-img{
    width: 175px;
}
.main-container {
    /* position: absolute; */
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    background-color: rgba(0, 0, 0, 0.3);

    /* position: relative; */
}

.nav-logo {
    color: #fff !important;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;

    font-weight: 700;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
    align-items: center;
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.nav-links-red {
    color: red !important;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;

    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.nav-links-active {
    color: #EBB044;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;

    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.fa-code {
    margin-left: 1rem;
}

.nav-item {
    line-height: 40px;
    margin-right: 0.9rem;
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
    width: 100%;
}

.nav-item.active {
    color: #ffdd40;
    border: 1px solid #ffdd40;
}

.nav-icon {
    display: none;
}

.mob-view-cta {
    display: none;
}

a:hover {
    color: #edd052 !important;
}

ul {
    /* margin-bottom: 0 !important; */
}

.chain-dropdown {
    background: none;
    border: 2px solid #fff;
    border-radius: 55px;
    height: 40px;
    color: #fff;
}

.backndlogo {
    display: flex;

    justify-content: center;
    align-items: center;
}

.section-2 {
    display: flex;
    justify-content: center;
    align-items: center;

}

.section-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.connect-wallet-btn {
    padding: 0 15px;
}

.chain-btn {
    width: 170px;
    height: 45px;
    /* padding: 10px 12px; */
    border-radius: 25px;
    border: none;
    background: #070b28;
}

.dropdown-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.join-cta {
    padding: 18px 36px;
    background: linear-gradient(96.31deg, #FBE043 -16.31%, #FF7E11 100%);
    border-radius: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #111310;
    cursor: pointer;
}

@media (min-width:951px) and (max-width:4000px) {

    .mob-view-cta,
    .mob-join-cta {
        display: none !important;
    }
}

@media screen and (max-width: 950px) {
    .logo-img{
width:120px!important;
    }
    .join-cta {
        margin-right: 40px;
        padding: 10px 15px !important;
    }

    .mob-view-cta{
        display: flex;
    }

    .jea-btn {
        display: none;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* border-top: 1px solid #fff; */
        position: absolute;
        top: 78px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;

    }

    .nav-menu.active {
        background: #011F1A;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        padding: 30px !important;
    }

    .nav-item .active {
        color: red;
        border: none;
    }

    .nav-links {
        padding: 0.5rem;
        width: 100%;
        display: table;
        color: #fff;
    }

    .nav-logo {
        font-size: 1.3rem !important;
    }

    .nav-icon {
        display: block;
        position: absolute;
        top: -7px;
        right: -35px;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #ffdd40;
    }

    .connect-wallet-btn,
    .info-dot {
        display: none;
    }

    .chain-btn {
        margin-right: 38px;
    }

    .navbar-nav {
        padding: 15px;
    }

}
@media (min-width:1000px) and (max-width:1100px) {
    .navbar-nav{
        padding: 20px 80px 0px;
    }
}