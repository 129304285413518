.ant-modal-footer{
    padding: 0px 16px 30px !important ;
text-align: center !important;
}
.ant-modal{
    max-width: calc(40vw - 32px) !important;
}
.ant-modal-content{
    border-radius: 20px !important;
}
.ant-modal-body{
    background: linear-gradient(105.76deg, rgba(255, 126, 17, 0.2) 1.35%, rgba(1, 31, 26, 0.2) 100%),url("../../assets/img-2.0/what-crypit-bg.png");

}
.Rocket-img{
    display: block;
    margin: auto;
    width: 150px;
    margin-bottom: 20px;
    height: 150px;
}
.flex-modal{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.flex-modal input{
    width:70%;

}
.join-cta{
    padding: 18px 36px;
    background: linear-gradient(96.31deg, #FBE043 -16.31%, #FF7E11 100%);
    border-radius: 10px;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
text-transform: capitalize;
color: #111310;
cursor: pointer;
border: 0;
}

.access-input{
    background-color: transparent!important;
    border-bottom: 1px solid #111310  !important ;
    border-right-width: 0px !important ;
    border-left-width: 0px !important ;
    border-top-width: 0px !important ;
    width: 90%;
    outline-style: none;
    padding: 2px 15px;
}
.ant-input:hover{
    border-color: transparent;
   
}
@media (min-width:350px) and (max-width:950px){
    .ant-modal-content{
        width: 130% !important;    
    }
    .ant-modal-body {
        padding: 10px!important;
    }
    .ant-modal-centered .ant-modal{
        left: -48px!important;
    }
}
@media (min-width:350px) and (max-width:1024px) {
    .join-cta{
        font-size: 14px!important;
    }
  
    .ant-modal{
        max-width: calc(70vw - 32px) !important;
    }
    .flex-modal{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  
    .flex-modal h3{
        display: none;
        }
        .flex-modal input{
            width:90%;
        
        }
}
@media (min-width:1025px) and (min-width:1500px) {
    ::-webkit-input-placeholder {
        /* WebKit browsers */
         color: transparent;
    }
     :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
         color: transparent;
    }
     ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
         color: transparent;
    }
     :-ms-input-placeholder {
        /* Internet Explorer 10+ */
         color: transparent;
    }
     input::placeholder {
         color: transparent;
    }
     textarea::-webkit-input-placeholder {
        /* WebKit browsers */
         color: transparent;
    }
     textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
         color: transparent;
    }
     textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
         color: transparent;
    }
     textarea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
         color: transparent;
    }
     textarea::placeholder {
         color: transparent;
    }
}