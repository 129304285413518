.early-bird-cont{
   position: relative;
    background: linear-gradient(105.76deg, rgba(255, 126, 17, 0.2) 1.35%, rgba(1, 31, 26, 0.2) 100%);
    padding: 72px 190px;
}
.early-bird-head{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 56px;
line-height: 150%;
color: #011F1A;
}
.input-email-eb{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #000;
    width: 100%;
    margin: 20px 0;
    padding: 10px 0;
    outline-style: none;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Montserrat';
}

.input-email-eb::placeholder {
    color: #000;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
}
.form-early-access{
    display: flex;
    flex-direction: column;
    margin: 25px 0px 15px ;
    width: 375px;

}
.Block1{
    width: 86px;
    height: 297px;
    position: absolute;
    top: 75px;
    right:300px;
}
.Block2{
    width: 86px;
    height: 297px;
    position: absolute;
    top: 215px;
right:450px;
}
.JustCrypit{
    position: absolute;
    top: 450px;
    right: 100px;
    width: 300px;
}
@media (min-width:325px) and (max-width:949px){
    .Block1{
        width: 86px;
        height: 297px;
        position: absolute;
        top: 75px;
        right:150px;
        opacity: 0.2;
    }
    .Block2{
        opacity: 0.2;
        width: 86px;
        height: 297px;
        position: absolute;
        top: 215px;
    right:250px;
    }
    .JustCrypit{
        opacity: 0.4;
        top: 450px;
        right:40px;
        width: 200px;
    }
}
@media (min-width:950px) and (max-width:1100px){
    .Block1{
        width: 86px;
        height: 297px;
        position: absolute;
        top: 75px;
        right:150px;
    }
    .Block2{
        width: 86px;
        height: 297px;
        position: absolute;
        top: 215px;
    right:250px;
    }
    .JustCrypit{
        position: absolute;
        top: 450px;
        right:40px;
        width: 200px;
    }
}
@media (min-width:325px) and (max-width:999px) {
    .early-bird-cont{
        padding: 129px 25px;
    }
    .early-bird-head{
        font-size: 32px;
    }
    .form-early-access{
        width: auto;
    }
}
