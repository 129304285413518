.home-comp {
    background-color: #011F1A;

}

.hero-sec {
    padding: 1px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:50px 120px 56px;
}

.hero-main-img {
    width: 615px;
    height: 580px;
    /* height: 491.14px; */
    /* margin: auto; */
}

.just-crypit {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 90px;
    line-height: 130%;
    /* or 135px */
    background: linear-gradient(94.59deg, #FF7E11 1.33%, #FBE043 52.86%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 5px 0;
}

.title-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    /* identical to box height, or 36px */


    color: #FFFFFF;
}

.title-text-span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: #FBE043;
}

.hero-email-input {
    background: transparent;
    border: 0;
    outline-style: none;
    color: #fff;
    padding-left: 15px;
    width: 220px;
}

.hero-email-input::placeholder {
    color: #A6A6A6;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
}

.hero-join-early-button {
    background: #F1F3F2;
    /* backdrop-filter: blur(50px); */
    border-radius: 8px;
    width: 187px;
    padding: 22px 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #414240;
    border: 1px solid #fff;
}

.join-early-combo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 443px;
    height: 64px;
    border: 1px solid #A6A6A6;
    margin-top: 40px;
    border-radius: 8px;
}

.follow-handles{
    display: flex;
    align-items: center;
    margin-top: 100px;
}
.follow-text{    
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 130%;
color: #A6A6A6;
margin-right: 34px;
}
.hero-card-section{
    padding:0 120px  56px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}
.single-card{
    height: 163px;
    display: flex;
    align-items: flex-start;
    padding: 30px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
   margin-bottom: 20px;
}
.card-description{
    margin-left: 16px;
}
.desc-heading{
    letter-spacing: -0.5px;
text-transform: uppercase;
color: #FFC300;
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 150%;
margin-bottom: 5px;
letter-spacing: 1px;
}

.desc-sub-heading{
  
    font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 150%;
color: #FFFFFF;
opacity: 0.5;
}
.card-img{
   width: 65px;
    background-color: #011F1A;
    padding: 15px;
    border-radius: 8px;
}

@media (min-width:1000px) and (max-width:1099px) {
    .hero-sec  {
        padding: 50px 26px 56px 50px;
    }
    .hero-card-section{
        padding: 0 50px 56px ;
    }
    .just-crypit{
        font-size: 74px;    
    }
    .title-text-span,.title-text{
        font-size: 20px;
    }
    .hero-main-img{
        height: 500px!important;
        width: 515px;
    }
    .single-card{
        width: 89%;
    }
}
@media (min-width:325px) and (max-width:999px) {
 
    .hero-sec{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 75px 25px 30px; 
    }
    .just-crypit{
        font-size: 52px;
        text-align: center;
        margin-bottom: 30px;
    }
    .title-text{
        font-size: 18px;
        text-align: center;

    }
    .join-early-combo{
        width: 343px;
        margin:30px auto 10px;
    }
    .hero-join-early-button{
        width: 160px;
        padding: 21px 10px;
        font-size: 13px;
    }
    .follow-handles{
        margin-top: 50px;
        justify-content: center;
    }
    .hero-main-img {
        width: 370px;
    margin-top: 60px;
    height: 370px!important;
    }
    .hero-email-input{
        width: auto;
    }
    .hero-card-section{
        padding: 0 25px 56px;
    }
    .single-card{
        height: auto!important;
        padding: 15px 10px;
    }
}
@media (min-width:1100px) and (max-width:1199px) {
    .hero-sec {
        padding: 50px 25px 56px 50px;
    }
    .hero-main-img{
        width: 515px;
        height: 540px!important;
    }
    .hero-card-section{
        padding: 0 50px 56px ;
    }
    .just-crypit{
        font-size: 74px;    
    }
    .title-text-span,.title-text{
        font-size: 20px;
    }
    .single-card{
        width: 89%;
    }
    .hero-card-section{
justify-content: center;
    }
}
@media (min-width:1200px) and (max-width:1300px) {
    .hero-sec  {
        padding: 50px 25px 56px 80px;
    }
    .hero-card-section{
        padding: 0 50px 56px ;
    }
.just-crypit{
    font-size: 84px;    
}
    .desc-sub-heading{
        width: 415px;
    }
}
@media (min-width:1301px) and (max-width:1400px) {
    .hero-sec  {
        padding: 50px 88px 56px;
    }
    .just-crypit{
        font-size: 84px;    
    }
    .desc-sub-heading{
        width:390px;
    }
}
@media (min-width:1401px) and (max-width:1500px) {
    .desc-sub-heading{
        width: 422px;
    }
}
@media screen and (min-width:1501px) {
    .desc-sub-heading{
        width:470px;
    }
}