.faq-section{
    padding: 78px 100px;
    background: linear-gradient(105.76deg, rgba(255, 126, 17, 0.2) 1.35%, rgba(1, 31, 26, 0.2) 100%);
    border-top: 2px solid #aeb4b0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.faq-heading{
    width: 810px;
    margin:5px auto 20px;
    font-style: normal;
font-weight: 700;
font-size: 56px;
line-height: 150%;
display: flex;
align-items: center;
text-align: center;
text-transform: capitalize;
color: #011F1A;

}
.faq-join-cta{
    width: 212px;
    padding: 18px 36px;
    background: linear-gradient(96.31deg, #FBE043 -16.31%, #FF7E11 100%);
    border-radius: 10px;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
text-transform: capitalize;
color: #111310;
cursor: pointer;
margin:20px auto 10px;
text-align: center;
}

@media (min-width:1000px) and (max-width:1100px) {
    .faq-sec {
        padding: 80px 25px!important;
    }
}

@media (min-width:1101px) and (max-width:1200px) {
    .faq-sec {
        padding: 80px 50px!important;
    }
}

@media (min-width:325px) and (max-width:999px) {
   
    .faq-section{
        padding: 78px 20px!important;
    }
    .faq-heading{
        width: auto;
        font-size: 32px;
        text-align: center;
    }
}