.footer-section{
    background: #011F1A;
    padding: 80px 120px 30px;
}
.footer-detail{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 64px;
    border-bottom:1px solid #A6A6A6 ;
}

.crypit-name{
    font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 150%;
color: #FFFFFF;
}
.footer-description{
    font-style: normal;width: 304px;
font-weight: 500;
font-size: 18px;
line-height: 150%;
color: #A6A6A6;
margin-top: 20px;
}

.follow-handles{
    display: flex;
    align-items: center;
    margin-top: 56px;
}
.footer-part{
    display: flex;
}
.footer-part-title{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #F1F3F2;
    margin-bottom: 24px;
    cursor: pointer;
    text-decoration: none!important;
}

.footer-part1, .footer-part2{
    padding-right: 140px;
}
.contact-us-desc{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 20px;
width: 228px;
color: #A6A6A6;
}
.footer-reserved{
    font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 150%;
color: #A6A6A6;
padding-top: 24px;
}
.follow-handles-footer{
    display: flex;
    align-items: center;
    margin-top: 30px;
}

@media (min-width:1100px) and (max-width:1200px) {
    .footer-section{
        padding: 80px 50px 30px;
    }
}

@media (min-width:1000px) and (max-width:1099px) {
    .footer-section{
        padding: 80px 50px 30px;
    }
    .footer-part1, .footer-part2{
        padding-right: 50px;
    }
}
@media screen and (min-width:999px) {
    .mob-footer-part{
display: none;
    }
}
@media (min-width:325px) and (max-width:999px) {
    .footer-section{
        padding: 80px 30px 30px;
    }.footer-detail{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .contact-us-desc{
        display: none;
    }
    .mob-footer-part{
        display: flex;
        justify-content: space-between;

        justify-content: center;
        padding-top: 30px;
    }
   .mob-footer-part-sec{
    margin-right: 108px;
   }
    .footer-part{
        display: none;
    }
    .crypit-name{
        text-align: center;
    }
    .footer-reserved{
        font-size: 11px;
        text-align: center;
    }
}