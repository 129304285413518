.download-container{
    position: relative;
}

.back-50{
    background-color: #011F1A;
    height: 500px;
    padding: 140px 116px 10px;
}
.back-100{
    background: linear-gradient(105.76deg, rgba(255, 126, 17, 0.2) 1.35%, rgba(1, 31, 26, 0.2) 100%);

    height: 400px;
}
.download-sec{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:70px 308px 70px;
}
.dwl-app-title{
    padding: 90px 116px 10px;
}
.dwl-app-feature-sec-heading{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 130%;
    color: #111310;
}
.description-detail{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 150%;
color: #011F1A;
width: 575px;
margin: auto;
}
.mob1{
position: absolute;
top: 400px;
left: 350px;
}
.mob2{
    position: absolute;
    top: 320px;
    left: 650px;
}
.mob3{
    position: absolute;
    top: 400px;
    left: 970px;
}

@media (min-width:1000px) and (max-width:1100px) {
    .back-50{
        padding: 140px 50px 10px;
    }
    .download-sec{
        padding: 208px 50px 0;
    }
    .mob1{
        left: 100px;
    }
    .mob2{
            
    left: 400px;

    }
    .mob3{
        left: 700px;
    }
}

@media (min-width:1101px) and (max-width:1200px) {
    .back-50{
        padding: 140px 50px 10px;
    }
    .download-sec{
        padding: 208px 50px 0;
    }
    .mob1{
        left: 200px;
    }
    .mob2{
            
    left: 500px;

    }
    .mob3{
        left: 800px;
    }
}

@media (min-width:1201px) and (max-width:1300px) {
    .back-50{
        padding: 140px 50px 10px;
    }
    .download-sec{
        padding: 208px 50px 0;
    }
    .mob1{
        left: 250px;
    }
    .mob2{
            
    left: 550px;

    }
    .mob3{
        left: 850px;
    }
}

@media (min-width:1301px) and (max-width:1400px) {
    .back-50{
        padding: 140px 50px 10px;
    }
    .download-sec{
        padding: 75px 50px 0;
    }
    .mob1{
        left: 300px;
    }
    .mob2{
            
    left: 600px;

    }
    .mob3{
        left: 900px;
    }
}

@media (min-width:325px) and (max-width:999px){
    .back-50{
        padding: 100px 20px 10px;
        height: 350px;
    }
    .mob1, .mob2{
        width: 152px;
height: 274px;
    }
    .mob1 {
        position: absolute;
        top: 250px;
        left: 20px;
    }
    .mob2 {
        position: absolute;
        top: 300px;
        left: 200px;
    }
    .mob3{
        display: none;
    }
    .download-sec {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 75px 30px 0;
    }
    .description-detail{
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }
    .dwl-app-title {
        padding: 50px 20px 10px;
    }
    .dwl-app-feature-sec-heading{
        font-size: 36px;
    }
}