.faq-sec{
    background: #011F1A;
    padding: 80px 120px;
}
.handles{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 90px auto 10px;
}
.social-media-box{
    background: rgba(255, 255, 255, 0.08);
    border-radius: 24px;
padding: 32px;
}
.icon-bg{
background: #011F1A;
border-radius: 24px;
padding: 50px;
}
.user-name{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 130%;
color: #A6A6A6;
padding: 20px 0 15px;
text-align: center;
}

.user-id{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 130%;
/* or 23px */
text-align: center;

color: #FFFFFF;
}
@media (min-width:325px) and (max-width:999px) {
    .social-links-icon{
        width: 48px;
        height: 48px;
    }
    .faq-sec {
        padding: 30px;
    }
    .handles{
        margin: 40px auto 10px;
    }
    .icon-bg{
        padding: 25px;
        
    }
    .social-media-box{
        margin-bottom: 15px;
        padding:20px;
    }
    .social-icon{
        width: 48px;
        height: 48px;
        padding: 25px;
    }
    .user-name{
        font-size: 12px;
        
    padding: 10px 0 6px;

    }
    .user-id{
        font-size: 14px;
    }
}